.navbar-nav {
  margin: 1;
  padding: 7.5px 15px;
  overflow: hidden;
}

#bootstrap_menu.navbar-default .navbar-brand {
  color: rgba(119, 119, 119, 1);
}

#bootstrap_menu.navbar-default {
  font-size: 25px;
  background-color: rgba(255, 255, 255, 1);
  border-bottom-width: 0;
}

#bootstrap_menu.navbar-default .navbar-nav > li > a {
  color: rgba(119, 119, 119, 1);
  background-color: rgba(255, 255, 255, 1);
}

#bootstrap_menu.navbar-default .navbar-nav > li > a:hover,
#bootstrap_menu.navbar-default .navbar-nav > li > a:focus {
  color: rgba(51, 51, 51, 1);
  background-color: rgba(187, 197, 199, 1);
}

#bootstrap_menu.navbar-default .navbar-nav > .active > a,
#bootstrap_menu.navbar-default .navbar-nav > .active > a:hover,
#bootstrap_menu.navbar-default .navbar-nav > .active > a:focus {
  color: rgba(255, 255, 255, 1);
  background-color: rgba(36, 193, 224, 1);
}

#bootstrap_menu.navbar-default .navbar-toggle {
  border-color: #24c1e0;
}

#bootstrap_menu.navbar-default .navbar-toggle:hover,
#bootstrap_menu.navbar-default .navbar-toggle:focus {
  background-color: #24c1e0;
}

#bootstrap_menu.navbar-default .navbar-toggle .icon-bar {
  background-color: #24c1e0;
}

#bootstrap_menu.navbar-default .navbar-toggle:hover .icon-bar,
#bootstrap_menu.navbar-default .navbar-toggle:focus .icon-bar {
  background-color: #ffffff;
}

.top {
  background: linear-gradient(135deg, #1de9b6 0%, #2979ff 100%);
  font-family: "Lato", sans-serif;
  color: white;
  height: 100vh;
  width: 100vw;
  padding-top: 250px;
  padding-bottom: 200px;
  overflow: hidden;
}

.header {
  font-family: "Josefin Sans", sans-serif;
  font-size: 70px;
  overflow: hidden;
}

.title {
  font-family: "Josefin Sans", sans-serif;
  font-size: 80px;
  font-size: 8vw;
  overflow: hidden;
}

p {
  font-family: "Lato", sans-serif;
  font-size: 18px;
  overflow: hidden;
}

li {
  font-family: "Lato", sans-serif;
  font-size: 18px;
  padding: 1%;
}

html,
body {
  height: 100%;
  font-family: "Josefin Sans", sans-serif;
  max-width: 100% !important;
}

#arrow {
  position: absolute !important;
  bottom: 0 !important;
  color: white;
  opacity: 0.25;
}

#arrow:hover {
  font-size: 4em;
  transition: 1s ease-out;
  opacity: 1;
}

.fadeIn {
  opacity: 1;
  width: 100px;
  height: 100px;
  transition: width 0.5s, height 0.5s, opacity 0.5s 0.5s;
}

.fadeOut {
  opacity: 0;
  width: 0;
  height: 0;
  transition: width 0.5s 0.5s, height 0.5s 0.5s, opacity 0.5s;
}

a {
  color: grey !important;
}

.social .fa-github:hover {
  color: rgb(33, 200, 204);
}

.social .fa-linkedin:hover {
  color: rgb(37, 159, 230);
}

.container {
  max-width: 100% !important;
  overflow-x: hidden !important;
}

#hughImage {
  border-radius: 8px;
  width: 50%;
  height: auto;
  padding-top: 50px;
}

@media (max-width: 700px) {
  #hughImage {
    border-radius: 8px;
    width: 80%;
    height: auto;
    padding-top: 50px;
    background-size: contain;  }
  }
